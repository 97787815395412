import { Controller } from "stimulus"
import MoveTo from "moveto"

export default class extends Controller {
  static targets = ["openButton", "stepContainer"]

  connect() {
    this.toggleStep(this.initialStep)
  }

  get initialStep() {
    return parseInt(this.data.get("initial-step")) || 0
  }

  toggle(event) {
    const stepContainer = event.target.parentNode
    const indexOfStep = this.stepContainerTargets.indexOf(stepContainer)
    this.toggleStep(indexOfStep)
  }

  toggleStep(index, options = {}) {
    const show = (step) => {
      const element = step.querySelector(".accordion_step_content_container")

      const getHeight = () => {
        element.style.display = "block"
        const height = element.scrollHeight + "px"
        element.style.display = ""
        return height
      }

      const height = getHeight()
      step.classList.add("shown")
      element.style.height = height

      return new Promise((resolve, reject) => {
        window.setTimeout(() => {
          element.style.height = ""

          resolve(element)
        }, this.animationTimeout(300))
      })
    }

    const hide = (step) => {
      const element = step.querySelector(".accordion_step_content_container")
      element.style.height = element.scrollHeight + "px"

      window.setTimeout(() => { element.style.height = "0" }, this.animationTimeout(1))
      window.setTimeout(() => { step.classList.remove("shown") }, this.animationTimeout(300))
    }

    if (this.stepContainerTargets) {
      const currentStep = this.stepContainerTargets[index]

      this.stepContainerTargets.forEach(step => {
        if (currentStep !== step) hide(step)
      })

      show(currentStep).then(element => {
        const moveTo = new MoveTo({ duration: 500, easing: "easeOutQuart" })
        moveTo.move(currentStep)
      })
    }
  }

  animationTimeout(value) {
    return document.body.classList.contains("test") ? 1 : value
  }
}
