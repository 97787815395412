import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["form", "input", "infoPanel", "submitButton"]

  connect() {
    if (this.hasSubmitButtonTarget) this.submitButtonTarget.style.display = "flex"
    if (this.hasInputTarget) this.inputTarget.style.display = "none"
    if (this.hasInfoPanelTarget) this.infoPanelTarget.style.display = "none"
  }

  get confirmationMessage() {
    return this.data.get("confirmation-message")
  }

  finalize(event) {
    event.preventDefault()

    if (this.confirmationMessage) {
      const confirmation = confirm(this.confirmationMessage)
      if (confirmation == true && this.hasFormTarget) {
        this.inputTarget.querySelector("input[type=\"hidden\"]").value = true
        this.submitButton.click()
      }
    } else {
      this.inputTarget.querySelector("input[type=\"hidden\"]").value = true
      this.submitButton.click()
    }
  }

  get submitButton() {
    return this.formTarget.querySelector("input[type=\"submit\"]")
  }
}
