import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["refreshButton"]

  connect() {
    if (this.hasRefreshButtonTarget) {
      this.refreshButtonTarget.click()
    }
  }

  showPhoneNumber(event) {
    const phoneNumber = event.currentTarget.dataset.phoneNumber
    this.phoneNumberDialogTextContainer.innerHTML = phoneNumber

    event.preventDefault()
  }

  get phoneNumberDialogTextContainer() {
    return document.querySelector("#phone_number_dialog_text_container")
  }
}
