import { Controller } from "stimulus"

export default class extends Controller {
  dragCommitment(event) {
    const serviceID = event.currentTarget.dataset.serviceId
    event.dataTransfer.setData("serviceID", serviceID)
  }

  dropCommitment(event) {
    event.preventDefault()
    event.currentTarget.classList.remove("drag_entered")

    const serviceID = event.dataTransfer.getData("serviceID")

    if (serviceID) {
      const newBookingLink = event.currentTarget.querySelector(".not_booked_yet a")
      const newBookingPath = `${newBookingLink.getAttribute("href")}&bookable_id=${serviceID}`
      Rails.ajax({url: newBookingPath, type: "get" })
    }
  }

  prepareDrop() {
    event.preventDefault()
    event.currentTarget.classList.add("drag_entered")
  }

  cancelDrop(event) {
    event.currentTarget.classList.remove("drag_entered")
  }
}
