import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [
    "submitButton",
    "acceptTermsCheckbox",
    "acceptAutomaticPaymentCheckbox"
  ]

  connect() {
    this.toggleSubmitButton()
  }

  toggleSubmitButton() {
    if (this.hasAcceptAutomaticPaymentCheckboxTarget &&
        this.hasAcceptAutomaticPaymentCheckboxTarget) {
      if (this.acceptTermsCheckboxTarget.checked &&
        this.acceptAutomaticPaymentCheckboxTarget.checked) {
        this.submitButtonTarget.disabled = false
      } else {
        this.submitButtonTarget.disabled = true
      }
    }
  }
}
