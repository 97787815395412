import { Controller } from "stimulus"
import Moment from "moment"

const moment = Moment

export default class extends Controller {
  static targets = ["timeIndicator", "timeIndicatorLabel"]

  connect() {
    if (this.hasTimeIndicatorTarget) {
      this.timeIndicatorTarget.style.display = "block"
      this.prepareTimeIndicator()
      this.startRefreshing()
    }
  }

  disconnect() {
    this.stopRefreshing()
  }

  get timeFormat() {
    return "H:m"
  }

  prepareTimeIndicator() {
    const baseScale = 100 / 1440
    const currentDate = new Date()
    const midnight = new Date(currentDate).setHours(0, 0, 0, 0)
    const minutesSinceMidnight = parseInt((currentDate - midnight) / 60000)
    const timeIndicatorTop = `${minutesSinceMidnight * baseScale}%`
    const localizedTime = moment(currentDate).format(this.timeFormat)

    this.timeIndicatorLabelTarget.textContent = localizedTime
    this.timeIndicatorTarget.style.top = timeIndicatorTop
  }

  startRefreshing() {
    this.refreshTimeIndicator = setInterval(() => {
      this.prepareTimeIndicator()
    }, 1000)
  }

  stopRefreshing() {
    if (this.refreshTimeIndicator) {
      clearInterval(this.refreshTimeIndicator)
    }
  }
}
