import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["serviceRow"]

  filter(event) {
    if (this.hasServiceRowTarget) {
      const keyword = event.currentTarget.value.toLowerCase()
      this.serviceRowTargets.forEach((element) => {
        const searchIndex = element.dataset.searchIndex
        element.classList.remove("found")

        if (searchIndex.toLowerCase().includes(keyword)) {
          element.classList.add("found")
        }
      })

      this.element.querySelectorAll(".site").forEach((element) => {
        element.classList.remove("no_results")
      })

      if (keyword.length == 0) {
        this.element.classList.remove("filtering")
      } else {
        this.element.classList.add("filtering")

        this.element.querySelectorAll(".site").forEach((element) => {
          const results = element.querySelectorAll(".service.found")

          if (results.length == 0) {
            element.classList.add("no_results")
          }
        })
      }
    }
  }
}
