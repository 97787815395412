import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["feedbackButton"]

  connect() {
    if (this.hasFeedbackButtonTarget && this.showFeedbackDialog) {
      this.feedbackButtonTarget.click()
    }
  }

  get showFeedbackDialog() {
    return this.data.get("show-feedback-dialog") == "true"
  }
}
