import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["zoomSlider"]

  initialize() {
    let savedZoomLevel = localStorage.getItem("weekCalendarZoomLevel")

    if (!savedZoomLevel) {
      savedZoomLevel = this.zoomLevel
    }

    this.zoomToLevel(savedZoomLevel)
    this.zoomSliderTarget.value = savedZoomLevel
  }

  zoomCalendar(event) {
    console.log(this.zoomLevel)
    this.zoomToLevel(this.zoomLevel)
  }

  zoomToLevel(level) {
    this.calendarElement.style.height = level + "vh"
  }

  saveZoomLevel(event) {
    localStorage.setItem("weekCalendarZoomLevel", this.zoomLevel)
  }

  get zoomLevel() {
    return this.zoomSliderTarget.value
  }

  get calendarElement() {
    return document.querySelector(".schedule_container")
  }
}
