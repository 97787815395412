import consumer from "./consumer"

global.channels = {}
global.channels.meetingRoom = {
  subscribe: (meetingRoomUUID) => {
    return consumer.subscriptions.create({ channel: "MeetingRoomChannel", id: meetingRoomUUID }, {
      connected() {
      },
      disconnected() {
      },
      received(data) {
        this.meetingRoomController().reload()
      },
      meetingRoomController() {
        const meetingRoomTarget = document
          .querySelector("div[data-controller=\"meeting-room\"]")
        return window.application.getControllerForElementAndIdentifier(
          meetingRoomTarget, "meeting-room"
        )
      }
    })
  }
}

