import { Controller } from "stimulus"

export default class extends Controller {
  stateObserver = {
    set: (target, property, value) => {
      target[property] = value

      if (property === "pushNotifierDeviceToken" && value && typeof webkit !== "undefined") {
        webkit.messageHandlers.currentAccount.postMessage("requestNotificationAuthorization")
      }

      return true
    }
  }

  connect() {
    this.state = new Proxy({}, this.stateObserver)
    this.state.id = this.data.get("id")
    this.state.pushNotifierDeviceTokens = JSON.parse(this.data.get("pushNotifierDeviceTokens"))
    this.state.locale = this.data.get("locale")
    this.state.isMobileApp = this.data.get("mobile-app") == "true"

    if (typeof webkit !== "undefined") {
      webkit.messageHandlers.currentAccount.postMessage("setPushNotifierDeviceToken")
      webkit.messageHandlers.currentAccount.postMessage(`locale${this.state.locale}`)
    }
  }

  setPushNotifierDeviceToken(token) {
    this.state.pushNotifierDeviceToken = token
  }

  registerPushNotifierDeviceToken(token, deviceType) {
    return new Promise((resolve, reject) => {
      if (this.state.pushNotifierDeviceTokens.includes(token)) {
        const data = { device_token: token }
        resolve(data)
      } else {
        Rails.ajax({
          url: this.data.get("apiV1PushNotifierDevicesPath"),
          type: "POST",
          data: `device_token=${token}&device_type=${deviceType}`,
          success: (data) => { resolve(data) }
        })
      }
    })
  }

  removePushNotifierDeviceToken(token) {
    return new Promise((resolve, reject) => {
      if (token) {
        Rails.ajax({
          url: `${this.data.get("apiV1PushNotifierDevicesPath")}/${token}`,
          type: "DELETE",
          success: (data) => {
            resolve(data)
          }
        })
      } else {
        resolve({})
      }
    })
  }

  signOut(event) {
    this.removePushNotifierDeviceToken(this.state.pushNotifierDeviceToken)
      .then((data) => { return true })
  }
}
