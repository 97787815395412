import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["bookingList"]

  dragBooking(event) {
    const bookingID = event.currentTarget.dataset.bookingId
    const moveBookingPath = event.currentTarget.dataset.moveBookingPath
    event.dataTransfer.setData("bookingID", bookingID)
    event.dataTransfer.setData("moveBookingPath", moveBookingPath)
  }

  dropBooking(event) {
    event.preventDefault()
    event.currentTarget.classList.remove("drag_entered")

    const timeslot = event.currentTarget.dataset.timeslot
    const bookingID = event.dataTransfer.getData("bookingID")
    const moveBookingPath = event.dataTransfer.getData("moveBookingPath")

    if (bookingID && moveBookingPath && timeslot) {
      if (this.hasBookingListTarget) {
        const loader = document.createElement("div")
        loader.classList.add("booking_list_loader")
        this.bookingListTarget.classList.add("loading")
        event.currentTarget.appendChild(loader)
      }

      Rails.ajax({url: `${moveBookingPath}?starts_at=${timeslot}`, type: "put" })
    }
  }

  prepareDrop() {
    event.preventDefault()
    event.currentTarget.classList.add("drag_entered")
  }

  cancelDrop(event) {
    event.currentTarget.classList.remove("drag_entered")
  }
}
