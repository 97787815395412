import { Controller } from "stimulus"
const Video = require("twilio-video")
import Moment from "moment"
import { extendMoment } from "moment-range"

const moment = extendMoment(Moment)

export default class extends Controller {
  static targets = [
    "startButton",
    "endButton",
    "connectionStatus",
    "callDuration",
    "remoteMedia",
    "localMedia"
  ]

  renderer = {
    set: (target, property, value) => {
      target[property] = value

      if (property === "connectionStatus") {
        if (value == "initialized") {
          this.showStartButton()
          this.disableStartButton()
          this.hideEndButton()
          this.disableEndButton()
        } else if (value == "not_ready") {
          this.hideStartButton()
          this.disableStartButton()
          this.hideEndButton()
          this.disableEndButton()
        } else if (value == "ready") {
          this.showStartButton()
          this.enableStartButton()
          this.hideEndButton()
          this.disableEndButton()
          this.preparePreview()
        } else if (value == "starting") {
          this.showStartButton()
          this.disableStartButton()
          this.hideEndButton()
          this.disableEndButton()
        } else if (value == "started") {
          this.hideStartButton()
          this.disableStartButton()
          this.showEndButton()
          this.enableEndButton()
          this.startTimer()
        } else if (value == "ending") {
          this.hideStartButton()
          this.disableStartButton()
          this.showEndButton()
          this.disableEndButton()
          this.endTimer()
        } else if (value == "ended") {
          this.hideStartButton()
          this.disableStartButton()
          this.hideEndButton()
          this.disableEndButton()
        } else if (value == "error") {
          this.hideStartButton()
          this.disableStartButton()
          this.hideEndButton()
          this.disableEndButton()
        } else if (value == "permission_error") {
          this.hideStartButton()
          this.disableStartButton()
          this.hideEndButton()
          this.disableEndButton()
        }

        this.showMessage()
      }

      return true
    }
  }

  connect() {
    this.state = new Proxy({}, this.renderer)
    this.state.uuid = this.meetingRoomUUID
    this.state.connectionStatus = "initialized"
    this.reload()
    this.meetingRoomChannel = global.channels
      .meetingRoom.subscribe(this.state.uuid)
    this.element.classList.remove("hide")
  }

  disconnect() {
    this.disconnectRoom()

    if (this.meetingRoomChannel) {
      this.meetingRoomChannel.unsubscribe()
    }
  }

  reload() {
    Rails.ajax({
      url: this.apiPath,
      type: "get",
      success: (response) => {
        if (this.isExternal) {
          this.startButtonTarget.href = response.data["external_url"]
        }

        this.state.accessToken = response.data["access_token"]
        this.state.url = response.data["url"]
        this.state.status = response.data["status"]

        if (this.state.status == "created" && !this.isWaiting) {
          this.state.connectionStatus = "not_ready"
        } else if (this.state.status == "created_and_prepared") {
          this.state.connectionStatus = "ready"
        } else if (this.state.status == "created_and_prepared_and_closed") {
          this.state.connectionStatus = "ended"
        }
      }
    })
  }

  async start(event) {
    if (this.isExternal) { return }

    event.preventDefault()

    this.state.connectionStatus = "starting"

    if (this.isMobileApp) {
      webkit.messageHandlers.meetingRoomAccessToken.postMessage(this.state.accessToken)
      return
    }

    const addParticipantTracks = (participant) => {
      let participantElement = this.remoteMediaTarget
        .querySelector(`[data-participant="${participant.identity}"]`)
      if (!participantElement) {
        participantElement = document.createElement("div")
        participantElement.classList.add("participant")
        participantElement.dataset.participant = participant.identity
      }
      this.remoteMediaTarget.appendChild(participantElement)

      participant.tracks.forEach(publication => {
        if (publication.isSubscribed) {
          const track = publication.track
          participantElement.appendChild(track.attach())
        }
      })

      participant.on("trackSubscribed", track => {
        participantElement.appendChild(track.attach())
      })
    }

    if (!this.tracks) {
      await this.createLocalTracks()
    }

    this.room = await Video.connect(this.state.accessToken, {
      audio: true,
      tracks: this.tracks
    })
    this.state.connectionStatus = "started"

    const localParticipant = this.room.localParticipant

    this.room.participants.forEach(participant => {
      if (localParticipant.identity != participant.identity) {
        addParticipantTracks(participant)
      }
    })

    this.room.on("participantConnected", participant => {
      addParticipantTracks(participant)
    })

    this.room.on("participantDisconnected", participant => {
      const participantElement = this.remoteMediaTarget
        .querySelector(`[data-participant="${participant.identity}"]`)
      participantElement.remove()
    })

    this.room.on("disconnected", room => {
      this.room.localParticipant.tracks.forEach(publication => {
        const attachedElements = publication.track.detach()
        attachedElements.forEach(element => element.remove())
        publication.track.stop()
        this.tracks = undefined
      })
    })

    window.addEventListener("beforeunload", () => this.disconnectRoom())
    window.addEventListener("pagehide", () => this.disconnectRoom())
  }

  end(event) {
    if (event) event.preventDefault()

    this.state.connectionStatus = "ending"
    this.disconnectRoom()
    this.state.connectionStatus = "ended"
  }

  fail(event) {
    if (event) event.preventDefault()

    this.state.connectionStatus = "ending"
    this.disconnectRoom()
    this.state.connectionStatus = "permission_error"
  }

  async preparePreview() {
    if (this.isExternal) { return }

    if (this.isPreviews) {
      await this.createLocalTracks()
    }
  }

  async createLocalTracks() {
    try {
      this.tracks = await Video.createLocalTracks()
      const localVideoTrack = this.tracks.find(track => track.kind === "video")
      if (this.localMediaTarget.childElementCount === 0) {
        this.localMediaTarget.appendChild(localVideoTrack.attach())
      }
    } catch(error) {
      if (error.name == "NotAllowedError") {
        this.state.connectionStatus = "permission_error"
      } else {
        this.state.connectionStatus = "error"
      }
    }
  }

  disconnectRoom() {
    if (this.room) this.room.disconnect()
    if (this.tracks) this.tracks.forEach(track => track.stop())
  }

  startTimer() {
    const startTimestamp = moment().startOf("day")
    this.timerInterval = setInterval(() => {
      startTimestamp.add(1, "second")
      this.callDurationTarget.innerHTML = `
        <span class="duration_label">${this.startedMessage}</span>
        <span class="duration">${startTimestamp.format("HH:mm:ss")}</span>
      `
    }, 1000)
  }

  endTimer() {
    clearInterval(this.timerInterval)
  }

  showStartButton() {
    this.startButtonTarget.classList.remove("hide")
  }

  enableStartButton() {
    this.startButtonTarget.classList.remove("disabled")
  }

  hideStartButton() {
    this.startButtonTarget.classList.add("hide")
  }

  disableStartButton() {
    this.startButtonTarget.classList.add("disabled")
  }

  showEndButton() {
    this.endButtonTarget.classList.remove("hide")
  }

  enableEndButton() {
    this.endButtonTarget.classList.remove("disabled")
  }

  hideEndButton() {
    this.endButtonTarget.classList.add("hide")
  }

  disableEndButton() {
    this.endButtonTarget.classList.add("disabled")
  }

  showMessage() {
    this.element.dataset.status = this.state.connectionStatus
  }

  get adapter() {
    return this.data.get("adapter")
  }

  get apiPath() {
    return this.data.get("api-path")
  }

  get meetingRoomUUID() {
    return this.data.get("uuid")
  }

  get startedMessage() {
    return this.data.get("started-message")
  }

  get isExternal() {
    return this.adapter == "zoom"
  }

  get isPreviews() {
    return this.data.get("preview") == "true"
  }

  get isWaiting() {
    return this.data.get("waiting") == "true"
  }

  get currentAccountController() {
    return this.application.getControllerForElementAndIdentifier(
      document.body, "current-account"
    )
  }

  get isMobileApp() {
    return this.currentAccountController.state.isMobileApp && typeof webkit !== "undefined"
  }
}
