import { Controller } from "stimulus"
import moment from "moment"

export default class extends Controller {
  static targets = [
    "accordion",
    "bookableStepSummary",
    "bookableDetailsLabel",
    "bookableProviderLabel",
    "bookablePriceLabel",
    "bookableMeetingRoomsLabel",
    "bookableLocationLabel",
    "bookableDurationLabel",
    "bookableCapacityLabel",
    "categorySelect",
    "bookableList",
    "dateAndTimepickerStepSummary",
    "startsAtLabel",
    "startsAtInput",
    "pickAvailableTimeButton",
    "serviceBanners",
    "timeslotTemplate",
    "timetableCalendar",
    "timetableCalendarLoader",
    "calendarTitle",
    "previousMonth",
    "nextMonth",
    "calendarCell",
    "availableTimeslots",
    "noAvailableTimeslotsInfoPanel",
    "questions",
    "couponCodeToggleButton",
    "couponCodeField",
    "confirmBookingButton"
  ]

  initialize() {
    moment().locale(this.locale)

    const startsAtObserver = {
      set: (obj, prop, value) => {
        obj[prop] = value

        let startsAtLabel = ""

        if (obj["date"] && obj["time"]) {
          const startsAtData = `${obj["date"]} ${obj["time"]}`
          const startsAtDate = moment(startsAtData)

          startsAtLabel = startsAtDate.format("LL")
          startsAtLabel += ` ${startsAtDate.format("HH:mm")}`

          this.startsAtInputTarget.value = startsAtData
          this.confirmBookingButtonTarget.disabled = false
          this.dateAndTimepickerStepSummaryTarget.style.display = null
        } else {
          this.startsAtInputTarget.value = null
          this.confirmBookingButtonTarget.disabled = true
          this.dateAndTimepickerStepSummaryTarget.style.display = "none"
        }

        this.updateDataLabelText(this.startsAtLabelTargets, startsAtLabel)

        return true
      }
    }

    const bookableObserver = {
      set: (obj, prop, value) => {
        obj[prop] = value

        if (obj["id"]) {
          this.pickAvailableTimeButtonTarget.disabled = false
        } else {
          this.pickAvailableTimeButtonTarget.disabled = true
        }

        if (obj["details"] && obj["provider"] && obj["price"]) {
          this.bookableStepSummaryTarget.style.display = null
        } else {
          this.bookableStepSummaryTarget.style.display = "none"
        }

        if (obj["category"] == "") {
          this.pickAvailableTimeButtonTarget.style.display = "none"
        } else {
          this.pickAvailableTimeButtonTarget.style.display = null
        }

        this.updateDataLabelText(this.bookableDetailsLabelTargets, obj["details"])
        this.updateDataLabelText(this.bookableProviderLabelTargets, obj["provider"])
        this.updateDataLabelText(this.bookablePriceLabelTargets, obj["price"])
        this.updateDataLabelText(this.bookableMeetingRoomsLabelTargets, obj["meetingRooms"])
        this.updateDataLabelText(this.bookableLocationLabelTargets, obj["location"])
        this.updateDataLabelText(this.bookableDurationLabelTargets, obj["duration"])
        this.updateDataLabelText(this.bookableCapacityLabelTargets, obj["capacity"])
        this.toggleMeetingRoomsLabel()
        this.filterBookableBanner()
        this.resetCouponCode()

        return true
      }
    }

    this.startsAt = new Proxy({}, startsAtObserver)
    this.bookable = new Proxy({}, bookableObserver)

    this.calendar = {}
    this.calendar.title = null
    this.calendar.isPreviousMonthPassed = false
    this.calendar.startDate = null
    this.calendar.previousStartDate = null
    this.calendar.nextStartDate = null
    this.calendar.days = []
  }

  connect() {
    this.filterBookables()
  }

  toggleBookablePicker(event) {
    event.preventDefault()

    this.accordionController.toggleStep(0)
  }

  toggleDateAndTimePicker(event) {
    event.preventDefault()

    this.accordionController.toggleStep(1)
  }

  toggleBookingConfirmation(event) {
    event.preventDefault()

    this.accordionController.toggleStep(2)
  }

  filterBookables(event) {
    this.bookable.category = this.categorySelectTarget.value
    this.bookable.id = null
    this.bookable.details = null
    this.bookable.provider = null
    this.bookable.price = null
    this.bookable.meeting_rooms = null
    this.bookable.location = null
    this.bookable.capacity = null
    this.bookable.duration = null
    this.startsAt.date = null
    this.startsAt.time = null

    const bookables = this.bookablesByCategory(this.bookable.category)

    this.bookableListTarget
      .querySelectorAll(".bookable")
      .forEach(el => el.style.display = "none")

    this.bookableListTarget
      .querySelectorAll("input[type='radio']")
      .forEach(el => el.checked = false)

    if (bookables.length === 1) {
      const bookableInput = bookables[0].querySelector("input[type=radio]:first-child")
      bookableInput.checked = true
      this.pickBookable(null, bookableInput.value)
    }

    bookables.forEach(el => el.style.display = null)
  }

  pickBookable(event, bookableId = undefined) {
    this.calendar.startDate = ""
    this.bookable.id = bookableId || event.currentTarget.value
    this.startsAt.date = null
    this.startsAt.time = null

    const bookablePanel = this.bookableListTarget
      .querySelector("#bookable_" + this.bookable.id)
    this.bookable.details = bookablePanel.dataset.bookableDetails
    this.bookable.provider = bookablePanel.dataset.bookableProvider
    this.bookable.price = bookablePanel.dataset.bookablePrice
    this.bookable.meetingRooms = bookablePanel.dataset.bookableMeetingRooms
    this.bookable.location = bookablePanel.dataset.bookableLocation
    this.bookable.capacity = bookablePanel.dataset.bookableCapacity
    this.bookable.duration = bookablePanel.dataset.bookableDuration

    this.fetchBookableTimetable()
    this.filterQuestions()
  }

  pickDate(event) {
    this.startsAt.date = event.currentTarget.dataset.date
    this.startsAt.time = null

    this.timetableCalendarTarget.querySelectorAll("td.selected")
      .forEach(el => el.classList.remove("selected"))
    event.currentTarget.classList.add("selected")
    this.filterTimeslots(this.getDateClassFor(event.currentTarget.id))
  }

  pickTime(event) {
    this.startsAt.time = event.currentTarget.dataset.timestamp

    this.availableTimeslotsTarget.querySelectorAll(".checked")
      .forEach(el => el.classList.remove("checked"))
    event.currentTarget.classList.add("checked")
  }

  loadCurrentMonth(event) {
    event.preventDefault()

    this.calendar.startDate = ""
    this.fetchBookableTimetable()
  }

  loadPreviousMonth(event) {
    event.preventDefault()

    this.calendar.startDate = this.calendar.previousStartDate
    this.fetchBookableTimetable()
  }

  loadNextMonth(event) {
    event.preventDefault()

    this.calendar.startDate = this.calendar.nextStartDate
    this.fetchBookableTimetable()
  }

  fetchBookableTimetable() {
    this.timetableCalendarLoaderTarget.style.display = "block"

    fetch(this.timetableBookablePath)
      .then(response => response.json())
      .then(json => {
        this.calendar.title = json["calendar_title_localized"]
        this.calendar.isPreviousMonthPassed = json["previous_month_has_passed"]
        this.calendar.startDate = json["start_date"]
        this.calendar.previousStartDate = json["previous_start_date"]
        this.calendar.nextStartDate = json["next_start_date"]
        this.calendar.days = json.days
        this.startsAt.date = null
        this.startsAt.time = null

        this.refreshCalendar()
        this.timetableCalendarLoaderTarget.style.display = "none"
      })
  }

  refreshCalendar() {
    this.availableTimeslotsTarget.innerHTML = ""
    this.calendarCellTargets.forEach(el => el.innerText = "")
    this.calendarTitleTarget.innerText = this.calendar.title

    if (this.calendar.isPreviousMonthPassed) {
      this.previousMonthTarget.style.display = "none"
    } else {
      this.previousMonthTarget.style.display = null
    }

    this.calendar.days.forEach(day => {
      const calendarCell = this.getCalendarCell(day["week_number"], day["weekday"])
      const currentDate = moment(day.date)
      const calendarCellInner = document.createElement("span")

      calendarCell.id = currentDate.format("YYYY_MM_DD")
      calendarCell.classList = []
      calendarCell.classList.add(...day["month_offset"])
      calendarCellInner.innerText = currentDate.date()

      calendarCell.setAttribute("data-date", currentDate.format("YYYY-MM-DD"))
      calendarCell.appendChild(calendarCellInner)

      if (day["available_timeslots"].length) {
        calendarCell.classList.add("has_timeslots")
      }

      day["available_timeslots"].forEach(timeslot => {
        const timestamp = moment(timeslot).format("HH:mm")
        const timeslotCell = document.importNode(
          this.timeslotTemplateTarget.content.querySelector("li"),
          true)
        timeslotCell.classList = `panel timeslot ${this.getDateClassFor(calendarCell.id)}`
        timeslotCell.innerText = timestamp
        timeslotCell.setAttribute("data-timestamp", timestamp)
        this.availableTimeslotsTarget.appendChild(timeslotCell)
      })
    })

    this.filterTimeslots(null)

    const todayCalendarCellID = moment(this.initialDate).format("YYYY_MM_DD")
    const todayCalendarCell = document.getElementById(todayCalendarCellID)
    if (todayCalendarCell) {
      todayCalendarCell.classList.add("today")
      todayCalendarCell.click()
    }
  }

  getCalendarCell(week, day) {
    return this.timetableCalendarTarget
      .querySelector("[data-week-index='" + week + "']")
      .querySelector("[data-day-index='" + day + "']")
  }

  getDateClassFor(calendarCellID) {
    return `for_date_${calendarCellID}`
  }

  filterTimeslots(dateClass) {
    const filteredTimeslots = this.availableTimeslotsTarget
      .querySelectorAll("." + dateClass)

    this.availableTimeslotsTarget.querySelectorAll("li").forEach(el => {
      el.style.display = "none"
      el.classList.remove("checked")
    })

    filteredTimeslots.forEach(el => el.style.display = null)

    if (filteredTimeslots.length > 0) {
      this.noAvailableTimeslotsInfoPanelTarget.style.display = "none"
    } else {
      this.noAvailableTimeslotsInfoPanelTarget.style.display = null
    }
  }

  filterQuestions(event) {
    this.questionsTarget
      .querySelectorAll(".question")
      .forEach(el => el.style.display = "none")

    this.questionsTarget
      .querySelectorAll(
        ".question[data-questionaire-id='" + this.bookable.id + "']"
      )
      .forEach(el => el.style.display = null)
  }

  toggleCouponCodeField(event) {
    event.preventDefault()

    this.couponCodeToggleButtonTarget.style.display = "none"
    this.couponCodeFieldTarget.style.display = null
    setTimeout(this.couponCodeFieldTarget.querySelectorAll("input")[0].focus(), 10)
  }

  resetCouponCode() {
    this.couponCodeToggleButtonTarget.style.display = null
    this.couponCodeFieldTarget.querySelectorAll("input").forEach(el => el.value = "")
    this.couponCodeFieldTarget.style.display = "none"
  }

  filterBookableBanner() {
    this.serviceBannersTarget.querySelectorAll(".service_banner")
      .forEach(el => el.style.display = "none")

    this.serviceBannersTarget.querySelectorAll(
        ".service_banner[data-bookable-id='" + this.bookable.id + "']"
      )
      .forEach(el => el.style.display = null)
  }

  updateDataLabelText(targets, text) {
    targets.forEach(el => {
      const dataLabel = el.querySelector(".data_label_text_content")
      dataLabel.innerText = this.valueOn(text)
    })
  }

  toggleMeetingRoomsLabel() {
    const display = this.bookable.meetingRooms == this.trueLocale ? "flex" : "none"
    this.bookableMeetingRoomsLabelTarget.style.display = display
  }

  bookablesByCategory(category) {
    return this.bookableListTarget.querySelectorAll(".bookable[data-bookable-category-id='" + category + "']")
  }

  valueOn(text) {
    return text || this.nilLocale
  }

  get timetableBookablePath() {
    let path = this.data.get("timetable-bookable-path")
    path = path.replace(":id", this.bookable.id)
    path = `${path}?start_date=${this.calendar.startDate}`

    return path
  }

  get accordionController() {
    return this.application.getControllerForElementAndIdentifier(
      this.accordionTarget, "accordion"
    )
  }

  get locale() {
    return document.getElementsByTagName("body")[0].dataset.locale
  }

  get initialDate() {
    return this.data.get("initial-date") || ""
  }

  get nilLocale() {
    return this.data.get("nil-locale") || ""
  }

  get trueLocale() {
    return this.data.get("boolean-true-locale") || ""
  }

  get falseLocale() {
    return this.data.get("boolean-true-locale") || ""
  }
}
