import { Controller } from "stimulus"

export default class extends Controller {
  connect() {
    this.dialogElement = document.querySelector(this.dialogSelector)
  }

  get dialogSelector() {
    return this.data.get("dialogSelector") || ""
  }

  present(event) {
    event.preventDefault()

    if (this.dialogElement) {
      window.dialog(this.dialogElement, false)
    }
  }
}
