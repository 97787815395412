import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["localeSelect"]

  renderer = {
    set: (target, property, value) => {
      target[property] = value

      if (property === "locale") {
        const localizableInputs = this.element.querySelectorAll(".localizable_input")
        localizableInputs.forEach((element) => {
          const locale = element.dataset.locale
          const displayValue = locale == value ? "block" : "none"
          element.style.display = displayValue
        })
      }

      return true
    }
  }

  initialize() {
    this.state = new Proxy({}, this.renderer)
    this.state.locale = this.localeSelectTarget.value
  }

  switchLocale(event) {
    this.state.locale = this.localeSelectTarget.value
  }
}
