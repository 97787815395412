import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["list"]

  connect() {
    if (this.hasListTarget) {
      this.listTarget.style.cursor = "pointer"
    }
  }

  goBackOneLevel() {
    const href = this.listTarget.querySelector("li:last-child a").href

    if (href) {
      Turbolinks.visit(href)
    }
  }
}
