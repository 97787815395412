import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["list", "table", "newItem", "addButton", "onboarding"]

  initialize() {
    this.newItemTemplate = document.createElement("template")
    this.newItemTemplate.innerHTML = this.newItemTarget.cloneNode(true).outerHTML

    this.listTarget.querySelectorAll(".destroy_field").forEach((element) => {
      const itemRow = element.parentNode.parentNode
      if (itemRow.value == "true") itemRow.style.display = "none"
    })

    this.isOnboarding ? this.showOnboarding() : this.hideOnboarding()
  }

  showOnboarding() {
    this.onboardingTarget.style.display = "flex"
    this.addButtonTarget.style.display = "none"
    this.tableTarget.style.display = "none"
  }

  hideOnboarding() {
    this.onboardingTarget.style.display = "none"
    this.tableTarget.style.display = "table"
    this.addButtonTarget.style.display = "flex"
  }

  addItem(event) {
    event.preventDefault()

    const timestamp = `${new Date().getTime()}`
    const newItemRowTemplate = this.newItemTemplate.cloneNode(true)
    newItemRowTemplate.innerHTML = newItemRowTemplate.innerHTML
      .replace(/attributes\]\[[0-9]+\]/g, `attributes][${timestamp}]`)
      .replace(/attributes_[0-9]+_/g, `attributes_${timestamp}_`)
    newItemRowTemplate.innerHTML = newItemRowTemplate.innerHTML
      .replace(/id\=\"date_picker_[a-z0-9]+\"/g, `id="date_picker_${timestamp}"`)
    newItemRowTemplate.innerHTML = newItemRowTemplate.innerHTML
      .replace(
        /data\-popover\-toggle\-popover\=\"#date_picker_[a-z0-9]+ /g,
        `data-popover-toggle-popover="#date_picker_${timestamp} `
      )
    console.log(newItemRowTemplate.innerHTML)
    const newItemRow = newItemRowTemplate.content.firstChild
    this.listTarget.appendChild(newItemRow)
  }

  removeItem(event) {
    event.preventDefault()

    const itemRow = event.currentTarget.parentNode.parentNode

    if (
      itemRow.dataset.target != "item-table.newItem" &&
      !window.confirm(event.currentTarget.dataset.confirmation)
    ) return

    if (itemRow) {
      itemRow.style.display = "none"
      const destroyField = itemRow.querySelector(".destroy_field")

      if (destroyField) destroyField.value = 1
    }
  }

  startEditing(event) {
    event.preventDefault()

    this.hideOnboarding()
  }

  get isOnboarding() {
    return this.data.get("onboarding") == "true"
  }
}

