import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["thumbnail"]

  markAsPurgable(event) {
    if (this.hasThumbnailTarget) {
      if (event.target.checked) {
        this.thumbnailTarget.classList.add("marked_as_purgable")
      } else {
        this.thumbnailTarget.classList.remove("marked_as_purgable")
      }
    }
  }
}
