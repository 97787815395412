import { Controller } from "stimulus"

export default class extends Controller {
  connect() {
    this.refresh()
  }

  refresh() {
    Rails.ajax({url: this.refreshPath, type: "get" })
  }

  get refreshPath() {
    return this.data.get("refresh-path")
  }
}
