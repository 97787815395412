import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["excerpt", "excerptText", "expandButton", "content"]

  initialize() {
    this.setExcerptHeight()

    if (this.isExpanded) {
      this.hideExcerpt()
      this.showContent()
    }
  }

  showExcerpt() {
    this.excerptTarget.style.display = "block"
  }

  hideExcerpt() {
    this.excerptTarget.style.display = "none"
  }

  showContent() {
    this.contentTarget.style.display = "block"
  }

  hideContent() {
    this.contentTarget.style.display = "none"
  }

  showExpandButton() {
    this.expandButtonTarget.style.display = "inline"
  }

  hideExpandButton() {
    this.expandButtonTarget.style.display = "none"
  }

  expand(event) {
    event.preventDefault()

    this.hideExcerpt()
    this.showContent()
  }

  setExcerptHeight() {
    const contentHeight = this.contentTarget.offsetHeight
    const excerptTextHeight = this.excerptTextTarget.offsetHeight

    if (contentHeight > 84) {
      if (excerptTextHeight > 84) this.excerptTextTarget.style.height = "84px"
      this.showExpandButton()
      this.showExcerpt()
      this.hideContent()
    } else {
      this.hideExpandButton()
      this.hideExcerpt()
      this.showContent()
    }
  }

  get isExpanded() {
    return this.data.get("expanded") == "true" ||
      window.location.hash.replace("#", "") == this.element.getAttribute("id")
  }
}
