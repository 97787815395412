import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["processingLoader", "topUpBalanceForm", "paymentMethodForm"]

  connect() {
    this.startRefreshing()
  }

  disconnect() {
    this.stopRefreshing()
  }

  startRefreshing() {
    if (this.hasProcessingLoaderTarget) {
      this.refreshBalance = setInterval(() => {
        this.loadBalance()
      }, 5000)
    }
  }

  stopRefreshing() {
    if (this.refreshBalance) clearInterval(this.refreshBalance)
  }

  loadBalance() {
    const hasProcessingLoaderBeforeRefresh = this.hasProcessingLoaderTarget
    const load = (url, formController) => {
      Rails.ajax({
        url: url,
        type: "GET",
        success: (response) => {
          if (hasProcessingLoaderBeforeRefresh && !this.hasProcessingLoaderTarget) {
            this.stopRefreshing()
          }
          setTimeout(() => {
            formController.toggleSubmitButton()
          }, 0)
        }
      })
    }

    load(this.balancePath, this.topUpBalanceFormController)
    load(this.paymentMethodPath, this.paymentMethodFormController)
  }

  onTopUpBalanceFormSuccess() {
    this.startRefreshing()
  }

  get balancePath() {
    return this.data.get("balance-path")
  }

  get paymentMethodPath() {
    return this.data.get("payment-method-path")
  }

  get topUpBalanceFormController() {
    return this.application.getControllerForElementAndIdentifier(
      this.topUpBalanceFormTarget, "top-up-balance-form"
    )
  }

  get paymentMethodFormController() {
    return this.application.getControllerForElementAndIdentifier(
      this.paymentMethodFormTarget, "payment-method-form"
    )
  }
}
