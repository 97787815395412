import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["bookingList"]

  dragBreak(event) {
    const breakID = event.currentTarget.dataset.breakId
    const moveBreakPath = event.currentTarget.dataset.moveBreakPath
    event.dataTransfer.setData("breakID", breakID)
    event.dataTransfer.setData("moveBreakPath", moveBreakPath)
  }

  dropBreak(event) {
    event.preventDefault()
    event.currentTarget.classList.remove("drag_entered")

    const timeslot = event.currentTarget.dataset.timeslot
    const breakID = event.dataTransfer.getData("breakID")
    const moveBreakPath = event.dataTransfer.getData("moveBreakPath")

    if (breakID && moveBreakPath && timeslot) {
      if (this.hasBookingListTarget) {
        const loader = document.createElement("div")
        loader.classList.add("booking_list_loader")
        this.bookingListTarget.classList.add("loading")
        event.currentTarget.appendChild(loader)
      }

      Rails.ajax({ url: `${moveBreakPath}?starts_at=${timeslot}`, type: "put" })
    }
  }

  prepareDrop() {
    event.preventDefault()
    event.currentTarget.classList.add("drag_entered")
  }

  cancelDrop(event) {
    event.currentTarget.classList.remove("drag_entered")
  }
}
