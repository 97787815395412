import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["updateNoticeContainer", "versionField", "submitForReviewButton"]
  renderer = {
    set: (target, property, value) => {
      target[property] = value

      if (property == "versions") {
        if (this.isDirty) {
          this.updateNoticeContainerTarget.classList.remove("closed")
          this.noticeTarget.classList.remove("error")
          this.noticeTarget.innerHTML = this.notSavedText
          if (this.hasSubmitForReviewButtonTarget) {
            this.submitForReviewButtonTarget.classList.add("disabled")
          }

          this.scheduleFormUpdate()
        }
      }

      if (property == "formSuccessTimestamp") {
        this.resetVersions()
        this.updateNoticeContainerTarget.classList.remove("closed")
        this.noticeTarget.classList.remove("error")
        this.noticeTarget.innerHTML = this.updatedText
        if (this.hasSubmitForReviewButtonTarget) {
          this.submitForReviewButtonTarget.classList.remove("disabled")
        }
      }

      if (property == "formErrorTimestamp") {
        this.updateNoticeContainerTarget.classList.remove("closed")
        this.noticeTarget.classList.add("error")
        this.noticeTarget.innerHTML = this.cantSaveText
        if (this.hasSubmitForReviewButtonTarget) {
          this.submitForReviewButtonTarget.classList.add("disabled")
        }
      }

      return true
    }
  }

  initialize() {
    this.state = new Proxy({}, this.renderer)

    this.resetVersions()

    this.formResponseHandler = (event) => {
      const eventStatus = event.detail[0].status
      if (eventStatus == 422) {
        this.state.formErrorTimestamp = new Date()
      }

      if (eventStatus == 200) {
        this.state.formSuccessTimestamp = new Date()
      }
    }
    this.formChangeHandler = () => { this.checkForChanges() }

    this.element.addEventListener("ajax:complete", this.formResponseHandler)
    this.element.addEventListener("change", this.formChangeHandler)
    this.editorTargets.forEach((editorTarget) => {
      editorTarget.addEventListener("trix-change", this.formChangeHandler)
    })
    this.inputTargets.forEach((editorTarget) => {
      editorTarget.addEventListener("keyup", this.formChangeHandler)
    })
  }

  disconnect() {
    clearTimeout(this.formUpdateTimeout)
    this.element.removeEventListener("ajax:complete", this.formResponseHandler)
    this.element.removeEventListener("change", this.formChangeHandler)
    this.editorTargets.forEach((editorTarget) => {
      editorTarget.removeEventListener("trix-change", this.formChangeHandler)
    })
    this.inputTargets.forEach((editorTarget) => {
      editorTarget.removeEventListener("keyup", this.formChangeHandler)
    })
  }

  scheduleFormUpdate() {
    clearTimeout(this.formUpdateTimeout)

    this.formUpdateTimeout = setTimeout(() => {
      Rails.fire(this.element, "submit")
    }, 1000)
  }

  checkForChanges() {
    const formData = this.prepareFormData()
    const latestData = this.state.versions[this.state.versions.length - 1]

    if (formData == latestData) return

    const versions = [...this.state.versions]

    versions.push(formData)
    this.state.versions = versions
    if (this.hasVersionFieldTarget) {
      this.versionFieldTarget.value = (parseInt(this.versionFieldTarget.value) || 0) + 1
    }
  }

  prepareFormData() {
    let formattedFormData = ""
    const formData = new FormData(this.element)

    for (var pair of formData.entries()) {
      if (!pair[0].includes("version_number")) formattedFormData += pair.join("")
    }

    return formattedFormData
  }

  resetVersions() {
    this.state.versions = [this.prepareFormData()]
  }

  get updatedText() {
    return this.data.get("updated-text")
  }

  get notSavedText() {
    return this.data.get("not-saved-text")
  }

  get cantSaveText() {
    return this.data.get("cant-save-text")
  }

  get isDirty() {
    return this.state.versions.length > 1
  }

  get editorTargets() {
    return this.element.querySelectorAll("trix-editor")
  }

  get inputTargets() {
    return this.element.querySelectorAll("input[type=\"text\"]")
  }

  get noticeTarget() {
    return this.updateNoticeContainerTarget.querySelector(".inline_notice")
  }
}
