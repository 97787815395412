import { Controller } from "stimulus"

export default class extends Controller {
  connect() {
    this.state = {}
  }

  prepare(event) {
    event.preventDefault()

    this.element.setAttribute("disabled", true)

    Rails.ajax({
      url: this.apiPath,
      type: this.apiType,
      success: (response) => {
        this.state.url = response.data["url"]
        this.state.status = response.data["status"]
        Turbolinks.visit(`${this.state.url}?wait=true`)
      }
    })
  }

  get apiPath() {
    return this.data.get("api-path")
  }

  get apiType() {
    return this.data.get("api-type")
  }
}
