import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["toggleSidebarButton"]

  toggleSidebar(event) {
    document.body.classList.toggle("sidebar_opened")
    event.preventDefault()
  }
}
