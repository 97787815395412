import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [
    "postcodeField",
    "cityField",
  ]

  searchCityForPostcode() {
    if (!this.postcode) return

    Rails.ajax({
      url: `${this.postcodesPath()}/${this.postcode}`,
      type: "GET",
      dataType: "json",
      success: (data) => { this.city = data.city }
    })
  }

  set city(city) {
    this.cityFieldTarget.value = city
    this.cityFieldTarget.focus()
  }

  get postcode() {
    return this.postcodeFieldTarget.value
  }

  postcodesPath() {
    return this.data.get("postcodes-path")
  }
}
