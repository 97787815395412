require("trix")
require("@rails/actiontext")
require("@rails/activestorage").start()
require("channels")

import "@stimulus/polyfills"

import { Application } from "stimulus"
import { definitionsFromContext } from "stimulus/webpack-helpers"

const application = Application.start()
const context = require.context("controllers", true, /.js$/)
application.load(definitionsFromContext(context))

Turbolinks.setProgressBarDelay(1000)

window.application = application

