import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [
    "amountField",
    "amountPresetField",
    "balanceLabel",
    "submitButton",
    "acceptTermsCheckbox"
  ]

  connect() {
    this.currentBalance = {
      amount: this.parseCurrentBalance(),
      currencySymbol: this.parseCurrentBalanceCurrencySymbol()
    }
    this.toggleSubmitButton()
  }

  pickAmountPreset(event) {
    const amountPresetField = event.currentTarget
    const amount = amountPresetField.dataset.amount

    this.amountPresetFieldTargets.forEach(el => el.classList.remove("checked"))
    amountPresetField.classList.add("checked")

    if (this.hasAmountFieldTarget) {
      this.amountFieldTarget.value = amount
      this.calculateBalance()
    }
  }

  selectAmountPreset(event) {
    const amount = this.currentAmount

    this.amountPresetFieldTargets.forEach(el => {
      el.classList.remove("checked")
      const amountPreset = parseInt(el.dataset.amount)

      if (amountPreset == amount) el.classList.add("checked")
    })
  }

  calculateBalance(event) {
    if (this.hasBalanceLabelTarget) {
      const amount = this.currentAmount
      const balanceLabel = this.balanceLabelTarget.querySelector(".data_label_text")
      const calculatedBalance = this.currentBalance.amount + amount
      balanceLabel.innerText = this.numberToCurrency(calculatedBalance)
    }
  }

  toggleSubmitButton() {
    this.submitButtonTarget.disabled = !this.acceptTermsCheckboxTarget.checked
  }

  get currentAmount() {
    let amount = 0

    if (this.hasAmountFieldTarget) {
      const rawAmount = this.amountFieldTarget.value.replace(/\s/g, "")
      amount = parseInt(rawAmount)

      if (isNaN(amount)) {
        return 0
      } else {
        return amount
      }
    } else {
      return amount
    }
  }

  parseCurrentBalance() {
    if (this.hasBalanceLabelTarget) {
      const rawBalance = this.balanceLabelTarget.dataset.balance
      const balance = parseInt(rawBalance)
      return balance
    }
  }

  parseCurrentBalanceCurrencySymbol() {
    if (this.hasBalanceLabelTarget) {
      return this.balanceLabelTarget.dataset.currencySymbol
    }
  }

  numberToCurrency(amount) {
    const formattedAmount = amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")
    return `${formattedAmount} ${this.currentBalance.currencySymbol}`
  }
}
